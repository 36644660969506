export const cities = [
  "Amsterdam",
  "Austin, Texas",
  "Beijing",
  "Bengaluru",
  "Boston",
  "Charlotte, N.C.",
  "Chennai",
  "Chicago",
  "Dallas, Texas",
  "Denver",
  "Dubai",
  "Dublin",
  "Frankfurt",
  "Hong Kong",
  "London",
  "Los Angeles",
  "Minneapolis",
  "Mumbai",
  "Munich",
  "Nashville",
  "New Delhi",
  "New York City",
  "Paris",
  "Phoenix, Ariz",
  "Pittsburgh",
  "Pune",
  "San Francisco",
  "Sao Paulo",
  "Seattle",
  "Seoul",
  "Shangai",
  "Shenzhen",
  "Singapore",
  "Sydney",
  "Tampa",
  "Tokyo",
  "Washington, D.C.",
  "Zurich"
];

export const country = [
  "Australia",
  "Brazil",
  "Canada",
  "China",
  "France",
  "Germany",
  "India",
  "Ireland",
  "Italy",
  "Japan",
  "Netherlands",
  "New Zealand",
  "Russia",
  "Singapore",
  "South Korea",
  "United Arab Emirates",
  "United Kingdom",
  "United States"
];

export const skills = [
  "Financial Analysis",
  "Valuation",
  "Financial Modeling",
  "Due Diligence",
  "Communication Skills",
  "Research Skills",
  "Networking",
  "Deal Sourcing",
  "Legal Understanding",
  "Negotiation Skills",
  "Teamwork"
];

export const jobRoles = [
  "Analyst",
  "Senior Analyst",
  "Associate",
  "Senior Associate",
  "Manager",
  "Vice President / Sr. Manager",
  "Principal / Director",
  "Managing Director / Partner",
  "C-Suite Executive",
  "Other"
];

export const industry = [
  "Private Equity",
  "Growth Equity",
  "Venture Capital",
  "Investment Banking",
  "Hedge Fund",
  "Sales & Training",
  "Asset Management",
  "Wealth Management",
  "Management Consulting",
  "Accounting",
  "Law",
  "Technology",
  "Start-up",
  "Other"
];

export const skillsByRole = {
  Analyst: [
    "Financial Modeling",
    "Analytical Skills",
    "Proficiency in Financial Software",
    "Knowledge of Economics and Accounting",
    "Data Visualization",
    "Risk Management",
    "Research Skills",
    "Communication Skills",
    "Decision-Making Skills",
    "Attention to Detail"
  ],
  "Senior Analyst": [
    "Advanced Financial Modeling",
    "Leadership Skills",
    "Strategic Thinking",
    "Advanced Knowledge of Financial Software",
    "Project Management",
    "Risk Analysis",
    "Presentation Skills",
    "Decision-Making Skills",
    "Mentoring",
    "Advanced Research Skills"
  ],
  Associate: [
    "Financial Literacy",
    "Analytical Skills",
    "Knowledge of Financial Software",
    "Communication Skills",
    "Attention to Detail",
    "Teamwork",
    "Problem-Solving Skills",
    "Time Management",
    "Basic Accounting Knowledge",
    "Customer Service Skills"
  ],
  "Senior Associate": [
    "Financial Analysis",
    "Leadership Skills",
    "Proficiency in Financial Software",
    "Excellent Communication Skills",
    "Attention to Detail",
    "Decision-Making Skills",
    "Project Management",
    "Advanced Accounting Knowledge",
    "Mentorship Skills",
    "Risk Management"
  ],
  Manager: [
    "Leadership Skills",
    "Financial Analysis",
    "Strategic Planning",
    "Proficiency in Financial Software",
    "Communication Skills",
    "Budgeting and Financial Planning",
    "Risk Management",
    "Project Management",
    "People Management",
    "Regulatory Compliance"
  ],
  "Vice President / Sr. Manager": [
    "Leadership and Strategic Vision",
    "Advanced Financial Analysis",
    "Risk Management",
    "Budgeting and Financial Planning",
    "Excellent Communication Skills",
    "Negotiation Skills",
    "Knowledge of Regulatory Compliance",
    "Project Management",
    "People Management",
    "Change Management"
  ],
  "Principal / Director": [
    "Strategic Leadership",
    "Advanced Financial Analysis",
    "Financial Forecasting and Planning",
    "Risk Management",
    "Excellent Communication Skills",
    "Business Acumen",
    "Regulatory Compliance Knowledge",
    "Negotiation Skills",
    "Team Building and People Management",
    "Change Management"
  ],
  "Managing Director / Partner": [
    "Leadership and Vision",
    "Advanced Financial Expertise",
    "Business Development",
    "Stakeholder Management",
    "Excellent Communication Skills",
    "Strategic Planning",
    "Risk Management",
    "Regulatory Compliance",
    "People Management",
    "Change Management"
  ],
  "C-Suite Executive": [
    "Strategic Financial Planning",
    "Leadership",
    "Risk Management",
    "Financial Analysis",
    "Communication",
    "Business Acumen",
    "Relationship Building",
    "Regulatory Compliance",
    "Decision Making",
    "Innovation"
  ],
  Other: [
    "Communication",
    "Decision Making",
    "Financial Analysis",
    "Leadership Skills",
    "Proficiency in Financial Software",
    "Innovation",
    "Teamwork",
    "Problem-Solving Skills",
    "Time Management",
    "Basic Accounting Knowledge",
    "Customer Service Skills"
  ]
};

export const colors = [
  "#005AC0",
  "#007DB7",
  "#2E84E6",
  "#6A8ED9",
  "#6499BE",
  "#97B5E4",
  "#028D77",
  "#40AD9D",
  "#7FC6BB",
  "#B8E0D2",
  "#81C497",
  "#6EB285",
  "#7151BF",
  "#8C76C3",
  "#AA94DA",
  "#C793E3",
  "#C271C9",
  "#A660C7",
  "#FBB5AB",
  "#F5B497",
  "#FEB26E",
  "#F4D165",
  "#FFE38B",
  "#E45F8D",
  "#FF6C6C",
  "#F58168",
  "#F88",
  "#F19585",
  "#FFA2A2",
  "#FFBEC1"
];



export const allJobScoreParameters = [
  "Cross-Functional Collaboration",
  "Content Strategy",
  "Risk Assessment",
  "Project Delivery",
  "Market Expansion",
  "Results-Driven",
  "Conflict Resolution",
  "Agile Methodology"
];